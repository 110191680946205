import _ from "lodash";

export const selectIsAuth = state => state.settings.isAuth;

export const selectIsRegistered = state => state.settings.isRegistered;

export const selectAuthRequired = state => state.settings.authRequired;

export const selectTwoFARequired = state => state.settings.twoFARequired;

export const selectIsRequiredMetaMask = state => state.settings.metamask;

export const selectDemoStatus = state => state.settings.isDemo;

export const selectLoading = state => state.settings.loading;

export const selectCurrentSymbol = state => state.settings.currentSymbol;

export const selectError = state => state.settings.error;

export const selectOrders = state => state.settings.socketData.orders;

export const selectPairOrders = key => state => selectOrders(state)[key];

export const selectPairsPnlHistory = state => state.settings.socketData.pnlHistory;

export const selectPairPnlHistory = key => state => selectPairsPnlHistory(state)[key];

export const selectPairsData = state => state.settings.corememData?.memory;

export const selectFilteredPairsData =
  (filter = (pairData, key) => pairData, toArray = true) =>
    state => {
      const pairsData = selectPairsData(state);
      const keys = selectPairKeys(state);

      const tempData = {};

      keys.forEach(key => {
        const filteredPairData = filter(pairsData[key], key);
        if (filteredPairData) {
          tempData[key] = filteredPairData;
        }
      });

      if (!toArray) return tempData;
      return Object.values(tempData);
    };

export const selectPairKeys = state => Object.keys(state.settings.corememData?.memory || {}); // please use this selector with _.isEqual function

export const selectPairData = key => state => (selectPairsData(state) || {})[key];
export const selectLicenseData = state => state.settings.licenseData;
export const selectPairItem = (key, item) => state => (selectPairData(key)(state) || {})[item];

export const selectConfig = state => state.settings.config;
export const selectIsDemo = state => selectConfig(state).GUI?.demo;
export const selectDemoLevel = state => selectConfig(state).GUI?.demoLevel || 'mm';
export const selectGunthyWallet = state => selectConfig(state).bot?.gunthy_wallet;
export const selectIsTryout = state => selectConfig(state).bot.isTryout;

export const selectConfigPairs = state => state.settings.config.pairs;
export const selectConfigPairs4bitRage = state => state.settings.config.pairs;
export const selectConfigExchanges = state => selectConfig(state).exchanges;
export const selectIsEmptyConfigExchanges = state => _.isEmpty(selectConfigExchanges(state));

export const selectExchanges = state => Object.keys(selectConfig(state).exchanges || {});

export const selectStrategiesConfig = state => state.settings.config.strategies;

export const selectBalancesInfo = state => state.settings.balances;

export const selectBalanceInfo = (exchange, pair) => state =>
  (selectBalancesInfo(state) || []).find(balanceItem => balanceItem.exchange === exchange && balanceItem.pair === pair);

export const selectSettingsItem = key => state => state.settings[key];

export const selectActiveStatus = state => state.settings.active;

export default {
  selectActiveStatus,
  selectConfig,
  selectConfigExchanges,
  selectIsAuth,
  selectIsRegistered,
  selectAuthRequired,
  selectTwoFARequired,
  selectLoading,
  selectError,
  selectOrders,
  selectPairOrders,
  selectPairsData,
  selectLicenseData,
  selectConfigPairs,
  selectStrategiesConfig,
  selectPairData,
  selectPairItem,
  selectSettingsItem,
  selectDemoStatus,
  selectCurrentSymbol,
  selectConfigPairs4bitRage,
};
